.header-color {
  background: #bdc3c7;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2c3e50, #bdc3c7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid{
  background: white;  /* fallback for old browsers */
 
/*
 background: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7);  
 background: linear-gradient(to right, #2c3e50, #bdc3c7); 
 */

  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avatar-img{
  height: 250px;
  widows: 100px;
  padding-top: 1em;
  padding-bottom: 2em;
}

.home-img{
  height: 250px;
  widows: 100px;
  padding-top: 5em;
  padding-bottom: 2em;
  transform: rotate(90deg);
}

.firstSights{

  color: black;
  padding-bottom: 10em;
}

.firstSights i {
  color: black;
}

.social-links a {
  color: black
}

.banner-text{
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h1{
  font-size: 66px;
  font-weight: bold;
  color: white;
}

.banner-text h2{
  font-size: 46px;
  font-weight: bold;
  color: white;
}

.banner-text h5{
  font-size: 30px;
  font-weight: bold;
  color: white;
}

.banner-text hr{
  border-top: 5px dotted white;
  width:  75%;
  margin: auto;
}

.banner-text p{
  color: white;
  font-size: 20px;
  padding: 1em;
}

.VarSocialLinks{
  display: flex;
  justify-content: space-between;
  width: 20%;
  margin: auto;

}

.social-links i {
  color: black;
  font-size: 2em;
  padding: 8px;
}

.contact h3 {
  text-align: center;
  font: bold;
}

.contactDetails {
  display:flex;
  justify-content: center;
} 

.contactDetails a {
  color: black;
}

.contactDetails i {
  color: black;
  font-size: 2em;
  padding-left: 1em;
}
